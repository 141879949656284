'use client';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useMarketingHat from 'lib/hooks/useMarketingHat';
import usePiano from 'components/integrations/PianoPlacements/hooks/usePiano';
import { usePathname } from 'next/navigation';

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 28px 0 rgba(51, 51, 51, 0.1);
  z-index: 9999;
`;

const classMap = (path: string) => {
  if (path.includes('/recipes')) return '-recipe-detail';
  if (path.includes('/articles')) return '-article-detail';
  if (path === '/') return '-home';
  if (
    path.includes('reviews') ||
    path.includes('taste') ||
    path.includes('guides')
  )
    return '-review-detail';
  return '';
};

export default function PromotionalRibbon() {
  const pathname = usePathname();
  const { segment } = useMarketingHat(['anonymous', 'registrant', 'former']);

  const initPiano = usePiano({
    segment: segment,
    postHandlers: false,
  });

  useEffect(() => {
    if (segment) {
      initPiano();
    }
  }, [segment, initPiano]);

  if (!pathname) return null;

  return (
    <Banner className={`piano-container piano-ribbon${classMap(pathname)}`} />
  );
}
